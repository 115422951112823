body {
    background-color: antiquewhite;
    margin-top: 0px;
    color: #39FF14;
    background-color: #7851a9;
    text-align: center;
    margin: auto;
    font-family: 'Open Sans', sans-serif;
}

#navbar {
    background-color: #39FF14;
    padding: 20px;
    height: 55px;
    clear: both;
}

#navbar-title {
    color: #7851a9;
    max-width: 400px;
    float: left;
}

#navbar-title h1 {
    margin: 0px;
}

#navbar-buttons {
    text-align: right;
    float: right;
}

.button {
    margin: 20px;
    padding: 10px;
    text-decoration: none;
    background-color: #7851a9;
    color: #39FF14;
    border-radius: 10px;
    border-color: #39FF14;
    border-style: solid;
    border-width: 2px;
    margin: 10px;
    font-family: 'Open Sans', sans-serif;
}

#navbar-buttons a {
    margin: 10px;
    padding: 10px;
    text-decoration: none;
    background-color: #39FF14;
    color: #7851a9;
    border-radius: 10px;
    border-color: #7851a9;
    border-style: solid;
    border-width: 2px;
}

td a {
    color: #39FF14;
}

table {
    margin: 20px auto;
}

th {
    padding: 10px;
}

td {
    padding: 10px;
}

.react-autosuggest__suggestions-list {
    list-style: none;
}

.win-controls {
    margin: 10px;
}

.win-controls input {
    margin-top: 0px;
    margin-bottom: 0px;
}

.error {
    color: yellow;
}
